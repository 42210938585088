<template>
  <div class="index">
    <div class="container">
      <div class="ac3-title">
        <h2>项目服务</h2>
        <p>我们提供全程的服务，咨询、IT规划、数据中台建设、安全运维..</p>
      </div>

      <img src="@/assets/imgs/fw1.png" alt="" style="margin-bottom: 60px" />
    </div>
    <modal
      title="请支付阅读"
      btnType="1"
      modalType="middle"
      :showModal="showModal"
      @submit="showModal = false"
      @cancel="showModal = false"
    >
      <template v-slot:body>
        <img style="display: flex; justify-content: center; margin: 0 auto" src="./../../public/imgs/pay2.png" />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import a1 from "./../components/a1.vue";
import Modal from "./../components/Modal";
export default {
  components: {
    // a1,
    Modal,
  },
  data() {
    return {
      d1: [],
      d2: [],
      d3: [],
      d4: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "主鼎";
  },
  methods: {
    init() {
      this.axios.get("./../mock/info.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d1 = data.slice(0, 10);
        this.d2 = data.slice(2, 7);
        this.d3 = data.slice(10, 15);
        this.d4 = data.slice(6, 12);
        // console.log(this.data);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  .container {
    img {
      width: 100%;
    }

    .ac3-title {
      width: 100%;
      text-align: left;
      margin: 30px 0;
      h2 {
        font-size: 31px;
      }
      p {
        font-size: 14px;
        margin: 8px 0;
        color: #888;
      }
    }
  }
}
</style>
