<template>
  <div class="ac2" style="width: 100%">
    <div class="container">
      <!-- 容器 -->
      <div class="a2-con">
        <h2>{{ fullName }}</h2>
        <h4>{{ enName }}</h4>
        <div class="a2-box">
          <div class="r">
            <img src="@/assets/imgs/usinfo.jpg" alt="" />
          </div>
          <div class="l">
            <p>
              {{ fullName }}成立于{{ birth }}，注册地位于{{ addr
              }}。经营范围包括许可项目：互联网信息服务；互联网游戏服务；网络文化经营；第二类增值电信业务（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；动漫游戏开发；数字内容制作服务（不含出版发行）；计算机系统服务；软件开发；人工智能应用软件开发；专业设计服务；市场营销策划；广告设计、代理；广告发布；广告制作（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）等。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper.css";
import company from "../../company";
export default {
  name: "ac1",
  components: {},
  data() {
    return {
      fullName: company.fullName,
      enName: company.enName,
      birth: company.birthday,//"2022年09月08日",
      addr: company.address,

      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = company.title;
  },
  methods: {
    init1() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.splice(0, 3);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.ac2 {
  margin-top: 65px;

  .banner {
    width: 100%;
    height: 300px;

    img {
      width: 100%;
      // height: 300px;
      height: 100%;
    }
  }

  .container {

    // 容器
    .a2-con {
      margin: 40px 0;

      h2 {
        font-size: 28px;
        color: #333;
        text-align: right;
      }

      h4 {
        font-size: 18px;
        color: #888;
        text-align: right;
      }

      .a2-box {
        width: 100%;
        height: 450px;
        @include flex();

        .l {
          width: 60%;
          height: 100%;
          // background-color: #ccc;
          border-bottom: 2px dashed #ccc;
          padding: 30px;
          box-sizing: border-box;

          p {
            width: 100%;
            font-size: 14px;
            line-height: 50px;
            text-indent: 30px;
          }
        }

        .r {
          width: 40%;
          height: 100%;
          margin-left: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
