<template>
  <div class="n-footer">
    <div class="container">
      <!-- 关于我们 -->
      <div class="f-info">
        <!-- 标题 -->
        <h2>关于我们</h2>
        <p>
          经营范围包括许可项目：互联网信息服务；互联网游戏服务；网络文化经营；第二类增值电信业务（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以审批结果为准）一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让等...
        </p>
      </div>
      <!-- 网站导航 -->
      <div class="f-list">
        <h2>网站导航</h2>
        <a href="javascript:;" @click="goHome()">网站首页</a>
        <a href="javascript:;" @click="goAc()">行业资讯</a>
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->
        <a href="javascript:;" @click="goXy()">用户协议</a>
      </div>
      <!-- 联系我们 -->
      <div class="f-phone">
        <h2>联系我们</h2>
        <p>域名：{{ domain }}</p>
        <!-- <p>座机：010-50907019</p>
        <p>邮箱：13601130206@163.com</p> -->
        <p>地址：{{ address }}</p>
        <p>备案：<a href="https://beian.miit.gov.cn/#/Integrated/index">{{ beian }} </a></p>
      </div>
      <!-- 文字logo -->
      <div class="f-logo">
        <h2>{{ fullName }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import company from '../../company';
export default {
  data: () => {
    return {
      fullName: company.fullName,
      title: company.title,
      domain: company.domain,
      address: company.address,
      beian: company.beian,
    }
  },
  methods: {
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 资讯
    goAc() {
      this.$router.push("/ac1");
    },
    // 关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/base.scss";
@import "../assets/scss/config.scss";
@import "../assets/scss/mixin.scss";

.n-footer {
  width: 100%;
  height: 255px;
  background-color: #292f32;
  position: relative;

  .container {
    height: 100%;
    @include flex();

    // 关于我们
    .f-info {
      width: 25%;

      h2 {
        color: #fff;
      }

      p {
        font-size: 12px;
        color: #888;
        line-height: 25px;
        margin-top: 15px;
      }
    }

    // 网站导航
    .f-list {
      h2 {
        color: #fff;
      }

      a {
        text-align: center;
        font-size: 14px;
        color: #888;
        margin: 15px 0;
        display: block;
      }
    }

    // 联系我们
    .f-phone {
      width: 34%;

      h2 {
        color: #fff;
        margin-bottom: 25px;
      }

      p {
        font-size: 14px;
        color: #888;
        margin: 15px 0;

        a {
          font-size: 14px;
          color: #888;
        }
      }
    }

    .f-logo {
      h2 {
        font-size: 21px;
        color: #fff;
      }
    }
  }
}
</style>
