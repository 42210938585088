import { render, staticRenderFns } from "./xy.vue?vue&type=template&id=d0f54fe2&scoped=true"
import script from "./xy.vue?vue&type=script&lang=js"
export * from "./xy.vue?vue&type=script&lang=js"
import style0 from "./xy.vue?vue&type=style&index=0&id=d0f54fe2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.11.1_css-loader@6.8.1_vue-template-compiler@2.7.15_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0f54fe2",
  null
  
)

export default component.exports