<template>
  <div>
    <nav-header></nav-header>
    <router-view></router-view>
    <nav-footer :phone="this.phone" :name="this.addr" :addr="this.addr" :icp="this.icp"></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
import company from "../../company";
export default {
  name: "home",
  components: {
    NavFooter,
    NavHeader,
  },
  methods() {
    document.title = company.title;
  },
  data() {
    return {
      phone: `电话：${company.phone}`,
      icp: `备案号：${company.icp}`,
      addr: `地址：${company.address}`,
    };
  },
};
</script>
<style lang="" scoped></style>
