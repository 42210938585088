<template>
  <div class="index">
    <div class="container">
      <!-- 咨询区块1 -->
      <!-- <a1></a1> -->
      <!-- 容器 -->
      <div class="index-con">
        <!-- 左侧 -->
        <div class="l">
          <!-- 标题 -->
          <div class="l-title">行业资讯</div>
          <!-- 循环项 -->
          <div class="l-item" v-for="(item, index) in this.d1" :key="index" @click="goDays(item.methods, item.id)">
            <!-- 左侧图片 -->
            <div class="item-img">
              <img :src="item.img" alt="" />
            </div>

            <!-- 信息 -->
            <div class="item-info">
              <!-- 标题 -->
              <h2>{{ item.title }}</h2>
              <!-- 简介 -->
              <p>
                {{ item.jj }}
              </p>

              <!-- 信息层 -->
              <div class="item-time">
                <span><i class="iconfont icon-zuozhe"></i>作者：{{ item.author }}</span>
                <span><i class="iconfont icon-time"></i>发布时间：{{ item.time }}</span>
                <span><i class="iconfont icon-denglu"></i>{{ item.sub }}</span>
                <span>{{ item.pay }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 右侧 -->
        <div class="r">
          <!-- 资讯区块2 -->
          <div class="a2">
            <!-- 标题 -->
            <div class="a2-title"><i class="iconfont icon-wenzhang1"></i> 收费快讯</div>

            <!-- 循环项 -->
            <div class="a2-item" v-for="(item, index) in this.d2" :key="index" @click="goDays(item.methods, item.id)">
              <i></i>
              <div class="item-info">
                <span>{{ item.time }}</span>
                <p>{{ item.title }}</p>
              </div>
            </div>
          </div>

          <!-- 精选 -->
          <div class="a3">
            <h2>一周精选</h2>
            <!-- 循环项 -->
            <div class="a3-item" v-for="(item, index) in this.d3" :key="index" @click="goDays(item.methods, item.id)">
              <img :src="item.img" alt="" />
              <h4>{{ item.title }}</h4>
              <p>
                {{ item.article1 }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal title="请支付阅读" btnType="1" modalType="middle" :showModal="showModal" @submit="showModal = false" @cancel="showModal = false">
      <template v-slot:body>
        <img style="display: flex; justify-content: center; margin: 0 auto; width: 200px" src="./../../public/imgs/wx.png" />
        <h2>微信支付：2元/篇</h2>
      </template>
    </modal>
  </div>
</template>

<script>
// import a1 from "./../components/a1.vue";
import Modal from "./../components/Modal";
import company from "../../company";
export default {
  components: {
    // a1,
    Modal,
  },
  data() {
    return {
      d1: [],
      d2: [],
      d3: [],
      d4: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = company.title;
  },
  methods: {
    init() {
      this.axios.get("./../mock/info.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d1 = data.slice(0, 10);
        this.d2 = data.slice(2, 7);
        this.d3 = data.slice(10, 15);
        this.d4 = data.slice(6, 12);
        // console.log(this.data);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // if (this.state == 0) {
      //   // this.$router.push("/pay");
      //   this.showModal = true;
      // }
      // // 未登录
      // if (this.state !== 0) {
      //   alert("请登录付费2元后可浏览！！！~");
      //   this.$router.push({ path: "/login", query: { k: 1 } });
      //   return;
      // }
      this.showModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.index {
  .container {
    // height: 1500px;
    padding-top: 30px;
    box-sizing: border-box;

    // 容器
    .index-con {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      //   左侧
      .l {
        width: 75%;
        height: 100%;

        // background-color: #c60023;
        // 标题
        .l-title {
          width: 100%;
          height: 40px;
          border-left: 3px solid $colorZ;
          font-size: 16px;
          line-height: 40px;
          padding-left: 20px;
          box-sizing: border-box;
          font-weight: bold;
        }

        // 循环项
        .l-item {
          width: 100%;
          height: 200px;
          //   background-color: pink;
          margin: 15px 0;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            h2 {
              color: #ff6600;
            }
          }

          //   图片
          .item-img {
            width: 250px;
            height: 100%;

            // background-color: red;
            img {
              width: 100%;
              height: 100%;
            }
          }

          //   右侧
          .item-info {
            width: 70%;
            // height: 100%;
            padding-left: 20px;
            box-sizing: border-box;

            h2 {
              font-size: 18px;
              cursor: pointer;
              transition: all 0.2s;
            }

            p {
              font-size: 12px;
              color: #888;
              line-height: 30px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              margin: 15px 0;
            }

            .item-time {
              display: flex;

              span {
                font-size: 14px;
                margin-right: 15px;
                color: #888;

                i {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      // 右侧
      .r {
        width: 25%;
        height: 100%;
        // background-color: #ff6600;
        margin-left: 30px;

        // 咨询去看2
        .a2 {
          width: 100%;

          // height: 500px;
          // background-color: #c60023;
          // 标题
          .a2-title {
            width: 100%;
            height: 40px;
            border-left: 3px solid $colorZ;
            line-height: 40px;
            font-size: 16px;
            padding-left: 20px;
            box-sizing: border-box;

            i {
              font-size: 18px;
              margin-right: 10px;
            }
          }

          // 循环项
          .a2-item {
            width: 100%;
            height: 40px;
            // background: #fff;
            display: flex;
            margin: 20px 0;

            // justify-content: space-between;
            i {
              display: inline-block;
              width: 10px;
              height: 10px;
              margin-right: 15px;
              border-radius: 50%;
              background-color: $colorZ;
            }

            .item-info {
              width: 80%;

              span {
                font-size: 12px;
                font-weight: bold;
              }

              p {
                font-size: 16px;
                color: #888;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                transition: all 0.2s;
                cursor: pointer;

                &:hover {
                  color: #ff6600;
                }
              }
            }
          }
        }

        // 精选
        .a3 {
          width: 100%;
          height: 500px;
          margin: 20px 0;

          h2 {
            font-size: 14px;
            border-left: 3px solid $colorZ;
            height: 40px;
            line-height: 40px;
            padding-left: 20px;
            box-sizing: border-box;
          }

          // 循环项
          .a3-item {
            margin: 20px 0;
            cursor: pointer;

            img {
              width: 100%;
              height: 200px;
            }

            h4 {
              font-size: 16px;
              margin: 20px 0;
            }

            p {
              text-indent: 20px;
              font-size: 12px;
              color: #888;
              line-height: 20px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}</style>
